export const spisakNedelja = [
  "Седмица прва – Светла",
  "Седмица друга – Мироносица",
  "Седмица трећа – Раслабљеног",
  "Седмица четврта – Самарјанке",
  "Седмица пета – Слепога",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица шеста – Светих Отаца Првог васељенског сабора",
  "Седмица Педесетнице",
];
export const details = [
  { title: "Ime i prezime", name: "name" },
  { title: "Kontakt telefon", name: "tel" },
  { title: "Vaša poruka", name: "text" },
];
export const tretmanColors = [
  { tretman: "manastir", color: "#940922" },
  { tretman: "crkva", color: "#b29411" },
  { tretman: "ostalo", color: "#5F1A37" },
];
export const options = [
  //0
  {
    route: "/",
    title: "Ikone.rs",
    social: {
      title: "Ikone.rs",
      lead: "Ikone.rs - Ikone, crkveni kalendar, manastiri...",
      pics: "/img/test3.jpg",
    },
  },
  //1
  { route: "/manastiri", title: "Manastiri" },
  //2
  {
    route: "/ikone",
    title: "Ikone",
  },
  //3
  {
    route: "/crkveni-kalendar",
    title: "Crkveni kalendar",
    social: {
      title: "Crkveni kalendar",
      lead: "Crkveni pravoslavni kalendar | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar",
      pics: "/img/test3.jpg",
    },
    social2: {
      title: "Crkveni kalendar",
      lead: " | Svi praznici, slave i posti u godini na jednom mestu. Srpske crkve i manastiri, posni recepti, molitve | Crkveni kalendar ",
      pics: "/img/test3.jpg",
    },
    item_list: [
      {
        title: "Januar 2025",
        route: "/crkveni-kalendar/2025/januar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Februar 2025",
        route: "/crkveni-kalendar/2025/februar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Mart 2025",
        route: "/crkveni-kalendar/2025/mart",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "April 2025",
        route: "/crkveni-kalendar/2025/april",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Maj 2025",
        route: "/crkveni-kalendar/2025/maj",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Jun 2025",
        route: "/crkveni-kalendar/2025/jun",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Jul 2025",
        route: "/crkveni-kalendar/2025/jul",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Avgust 2025",
        route: "/crkveni-kalendar/2025/avgust",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Septembar 2025",
        route: "/crkveni-kalendar/2025/septembar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Oktobar 2025",
        route: "/crkveni-kalendar/2025/oktobar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Novembar 2025",
        route: "/crkveni-kalendar/2025/novembar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Decembar 2025",
        route: "/crkveni-kalendar/2025/decembar",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
    ],
  },
  //4
  {
    route: "/ostalo/meseceve-mene",
    title: "Mesečeve mene",
    social: {
      title: "Mesečeve mene",
      lead: "Mesečeve mene i lunarne faze u 2025. godini. MESEČEV KALENDAR. Precizan kalendar faza meseca za kompletnu godinu. Prikaz mesečevih mena za svaki mesec...",
      pics: ["/img/article_img/moon.jpg", "Pexels"],
    },
  },
  //5
  {
    route: "/zadusnice",
    title: "Zadušnice",
  },
  // { route: "/molitve", title: "Molitve" },
  // { route: "/slave", title: "Slave" },
  // { route: "/ostalo", title: "Ostalo" },
];

export const footer = [
  "O nama",
  "Marketing",
  "Pravila korišćenja",
  "Politika privatnosti",
];
