import React, { useEffect, useState } from "react";
import ArticleWidget from "./ArticleWidget.js";
import "./Widget.scss";

export default function Widget() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("https://agroweb.rs/filteredData.json").then((response) =>
      response
        .json()
        .then((data) => setData(data))
        .catch((error) => console.log("Error fetching data:", error))
    );
  }, []);
  return (
    <section className="home__box__3">
      <div className="home_3_title">
        <a href="https://agroweb.rs/" target="_blank">
          <h2>Agroweb.rs</h2>
        </a>
      </div>
      <div className="box3">
        {data && (
          <div className="box3__left">
            <ArticleWidget data={data[0]} classes="test1 box3" />
            <ArticleWidget data={data[1]} classes="test1 box3" />
            <ArticleWidget data={data[2]} classes="test1 box3" />
          </div>
        )}
      </div>
      <div className="box3__right"></div>
    </section>
  );
}
