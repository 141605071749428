import React from "react";

export default function ArticleWidget(props) {
  const { data, classes } = props;
  return (
    <div>
      <a target="_blank" className={classes} href={data.testUrl}>
        <div className="test11">
          <img src={data.pics[0]} alt="" />
          <div className="test12">
            <h2>{data.title}</h2>
            <h3 className="test112">
              {data.category} <span>| {data.time}</span>
            </h3>
          </div>
        </div>
      </a>
    </div>
  );
}
